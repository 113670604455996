//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { handleUploadCommon, selectPerson } from '@/mixins'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import { loadDefaultName } from '@/views/fileStream/documentSend/mixins'
import moment from 'moment'
import tableTitle from './components/tableTitle.vue'
import { commonFromValidate, customFromValidate, handleNodeList } from './minxins.js'
export default {
    props: {
        todoType: {
            type: String,
            default: 'registration',
        },
        //收文信息
        info: {
            type: Object,
            default() {
                return {}
            },
        },
        // 不可編輯
        isDisabled: {
            type: Boolean,
            default: false,
        },
        editType: {
            type: String,
            default: '',
        },
    },
    mixins: [loadDefaultName, selectPerson, handleNodeList, commonFromValidate, customFromValidate, handleUploadCommon],
    components: {
        OpenData,
        selectPersonDialog,
        tableTitle,
        nodes,
    },
    data() {
        return {
            form: this.$form.createForm(this),
            isEditTitle: false,
            formState: {
                name: '文件傳閱單',
                fileIds: [],
                attach: [],
            },
            moment,
            dom: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            list: [],
            fileList: [],
            fileAttachs: [],
            fileFileIdss: [],
            file_type: 1,
            upload_file_data: {},

            // 通訊錄數據
            // lwdw_list: [],
            jbr_list: [],
            isPreview: false,
            loading: false,
            loading1: false,
        }
    },
    computed: {
        isFieldValidating() {
            return false
        },
    },
    destroyed() {
        EventBus.$off('addDom', {})
    },
    created() {
        this.getDict()
        this.getList()
        this.getFromValidateState()
    },
    mounted() {
        this.$nextTick(() => this.documentTabDefaultByType(1, 'formState'))
    },
    watch: {
        // 經辦人
        jbr_list: {
            handler(val) {
                this.updateValidateState('draftId', !(val.length > 0))
                this.$set(this.formState, 'draftId', val)
            },
            deep: true,
            immediate: true,
        },
        formState: {
            handler(val) {
                console.log(val)
                EventBus.$emit('form_state', val, this.form)
            },
            deep: true,
            immediate: true,
        },
        info(val) {
            const { draftId } = val
            if (draftId) {
                this.jbr_list = Array.isArray(draftId) ? draftId : []
            }
            this.formState = val
        },
    },
    methods: {
        getFormObj() {
            const {
                form: { getFieldsValue },
            } = this
            let params = getFieldsValue()
            return { ...this.formState, ...params, jbr_list: this.jbr_list }
        },
        // 處理日期
        disabledDateHandler(current) {
            return current > moment().subtract(0, 'days').endOf('day')
        },
        uploadFile(file_type) {
            this.file_type = +file_type
        },
        /**
         * 上傳正文文件
         */
        customRequestAttach(data) {
            const size = data.file.size / 1024 / 1024 > 100
            const fileName = data.file.name
            let isRepeat = this.handleRepeatForFileName(data.file, this.formState.attach)
            let isRepeat1 = this.handleRepeatForFileName(data.file, this.formState.fileIds)
            if (isRepeat || isRepeat1) {
                return this.$message.error('上傳失敗：上傳文件相同')
            }
            const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            if (size) {
                this.$message.error('上傳失敗：文件大於100M')
                return
            }
            if (this.loading) return
            this.loading = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上傳成功')
                        this.$set(this.formState, 'attach', [
                            { fileName: data.file.name, fileId: res.data, fileType: 2 },
                        ])
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        /**
         * 上傳附件文件
         */
        customRequestFileIds(data, fileList) {
            debugger
            const size = data.file.size / 1024 / 1024 > 100
            const fileName = data.file.name
            let isRepeat = this.handleRepeatForFileName(data.file, this.formState.fileIds)
            let isRepeat1 = this.handleRepeatForFileName(data.file, this.formState.attach)
            if (isRepeat || isRepeat1) {
                return this.$message.error('上傳失敗：上傳文件相同')
            }
            const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            if (size) {
                this.$message.error('上傳失敗：文件大於100M')
                return
            }
            if (this.loading1) return
            this.loading1 = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    debugger
                    if (res.success) {
                        this.$message.success('上傳成功')
                        this.$set(this.formState, 'fileIds', [
                            ...this.formState.fileIds,
                            { fileName: data.file.name, fileId: res.data, fileType: 1 },
                        ])
                    }
                })
                .finally(() => {
                    this.loading1 = false
                })
        },
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
        },
        Filter(s) {
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        formatXzfn(xzfn) {
            return this.flow_file_type.find((j) => j.code === xzfn)?.value
        },
        onChange(date, dateString) {
            console.log(date, dateString)
        },
        getList() {
            EventBus.$on('addDom', (msg) => {
                this.list = msg
            })
        },
        // 預覽當前表格文件
        preview() {
            this.isPreview = !this.isPreview
            setTimeout(() => {
                let tableToPrint = document.getElementById('table') //將要被打印的表格
                let newWin = window.open('') //新打開一個空窗口
                newWin.document.write(tableToPrint.outerHTML) //將表格添加進新的窗口
                newWin.document.close() //在IE瀏覽器中使用必須添加這一句
                newWin.focus() //在IE瀏覽器中使用必須添加這一句
                newWin.print() //打印
                newWin.close() //關閉窗口
            })
        },
    },
}
